body {
    background: #101011;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

* {
    margin: 0;
    padding: 0;
}
